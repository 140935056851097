*{
  background-color: rgb(241, 87, 87);
  text-align: center;
}

.App{
  width: 100%;
  height: 100%;
}

h1{
  margin-top: 50px;
}

p{
  width: 75%;
  text-align: center;
  margin: auto;
  margin-top: 2vh;
  font-size: 24px;
}

img{
  margin-top: 2vh;
  height: 10vh;
  width: 5vw;
}

@media (max-width: 768px){
  img{
  height: 5vh;
  width: 5vw;
  }
}

iframe{
  border-color: red;
  border: solid;
}

.carousel {
  margin: 0 auto;
  max-width: 80%;
  background-color: none;
}

.carousel-img {
  width: 50vw;
  height: auto;
}

.wrap {
  text-align: center;
}

@media (max-width: 550px){
  .carousel-img {
    width: 75vw; 
    height: 50vh;
    object-fit: contain;
  }

  img{
    height: 10vh;
    width: 20vw;
    }

    iframe{
      width: 75vw;
      height: 25vh;
    }
}